body {
  margin: 0;
  font-family: Verdana, sans-serif;
}

.App {
  overflow-x: scroll;
  overflow-y: hidden;
}

.PageContent {
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: safe center;

  position: relative;
}

.Row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

@media print {
  @page {
    size: letter landscape;
  }

  .App {
    overflow-x: hidden;
  }

  .Settings {
    display: none;
  }

  .Page {
    height: 100vh;
    /* fill the print page so content can be bottom-aligned  */
  }
}